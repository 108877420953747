@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/***********************
  01. Common CSS
  02. Login Page CSS
  03. Main Page CSS
  04. Job Details Page CSS
  05. Job Select Page CSS
  06. Job Selected Page CSS
  07. Job List Page CSS
  08. Timekeeping Page CSS
  09. Work Report Page CSS
************************/

/* 01. Common CSS */

body {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  transition: all 0.3s ease 0s;
  display: inline-block;
  color: #151d4c !important;
}

a,
a:hover,
a:focus,
button,
button:focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
}

span,
strong {
  display: inline-block;
}

.back-menu {
  position: absolute;
  left: 105px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.errorMsg {
  color: #cc0000;
  /* margin-bottom: 12px; */
  font-size: 13px;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

p {
  margin: 0 !important;
  font-size: 16px;
}

.forgot-title p {
  font-size: 12px;
}

/**********************************************/
/* 02. Login Page CSS */
.login-page-header {
  padding: 10px 0;
  position: relative;
}

.login-page-header:before {
  content: "";
  position: absolute;
  right: 15px;
  left: 15px;
  height: 1px;
  bottom: 0;
  background-color: #161d4b;
}

.login-page .brand-logo {
  padding: 50px 0 30px 0;
}

.login-page {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.login-page-header a {
  color: #151d4c !important;
  text-transform: uppercase;
  font-weight: 600;
}

.login-page:before {
  content: "";
  position: absolute;
  width: 120%;
  height: 500px;
  top: -190px;
  background-color: #161e4c;
  border-radius: 60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.login-wrap {
  height: calc(100vh - 40px);
  background-color: #fff;
  position: relative;
  margin: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  overflow: overlay;
}

.login-form form label {
  display: block;
  margin: 0 0 5px 0;
  display: inline-block;
}

.login-form form input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  font-size: 16px;
  padding: 4px 10px;
  border-radius: 5px;
  color: #161e4b;
}

.login-form form button,
.button {
  display: block;
  width: 100%;
  border: 0;
  color: #fff !important;
  border-radius: 5px;
  padding: 5px 0;
}

.login-form form input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.login-form form input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.login-form form input:focus {
  outline: none;
}

.input-third {
  margin-top: 20px;
}

.input-second {
  margin-top: 15px;
}

.input-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin: 0 !important;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
}

.input-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #151d4c;
}

.input-checkbox:hover input~.checkmark {
  background-color: transparent;
  border: 1px solid #151d4c;
}

.input-checkbox input:checked~.checkmark {
  background-color: #161e4c;
}

.input-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.input-checkbox input:checked~.checkmark:after {
  display: block;
}

.input-checkbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.input-four {
  margin-top: 20px;
}

.forgot-password {
  text-align: center;
  padding-top: 50px;
}

/**********************************************/
/* 03. Main Page CSS */
.common-header {
  padding: 20px 0;
  border-bottom: 1px solid #161e4c;
  position: fixed;
  right: 0;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: #fff;
}

.main-section {
  padding: 160px 0px 95px 0;
}

.main-option {
  display: flex;
  padding: 30px 10px;
}

.main-option span {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0;
  text-align: left;
}

.main-option-img {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border: 2px solid #151d4c;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease 0s;
  align-items: center;
  box-shadow: 0 0 15px 0px #161e4c;
}

.main-option-img img {
  max-width: 42px;
}

.main-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  background-color: #151d4c;
}

.main-footer a,
.main-footer p {
  font-size: 16px;
  font-weight: bold;
  color: #fff !important;
  display: block;
  padding: 10px 0;
}

.main-option:hover {
  background: rgba(21, 29, 76, 0.2);
}

.main-option:hover .main-option-img {
  border-color: #ffffff;
}

.bm-item {
  color: #fff !important;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 30px;
  right: 120px;
  top: 40px;
}

.bm-burger-bars {
  background: #151d4c;
  opacity: 1 !important;
  transition: all 0.3s ease 0s;
}

.bm-cross-button {
  width: 24px !important;
  height: 30px !important;
}

.bm-cross {
  background: #ffffff !important;
}

.bm-menu {
  background: #161e4d;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-burger-button:hover .bm-burger-bars {
  background: #151d4c;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3) !important;
}

/**********************************************/
/* 04. Job Details Page CSS */

.custom-input label {
  margin: 0 0 5px 0;
  display: inline-block;
}

.custom-input input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  font-size: 16px;
  padding: 4px 10px;
  border-radius: 5px;
  color: #000;
}

.custom-input {
  margin-top: 15px;
}

input:focus {
  outline: none;
}

.custom-button a,
.custom-button button {
  border: 0;
  background-color: #151d4c;
  color: #fff !important;
  border-radius: 5px;
  padding: 5px 50px;
  text-transform: uppercase;
  margin-top: 30px;
}

.custom-button.link a,
.custom-button.button button {
  background-color: #28a745;
}

.custom-button {
  text-align: center;
}

.custom-button a img,
.custom-button button img {
  max-width: 16px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.two-input input:first-child {
  width: 70%;
  border-radius: 5px 0 0 5px;
}

.two-input input:last-child {
  width: 30%;
  border-radius: 0px 5px 5px 0px;
  border-left: 0;
}

/**********************************************/
/* 05. Job Select Page CSS */
.job-select-box button {
  border: none;
  background: transparent;
  font-weight: 600;
  text-transform: capitalize;
  padding: 5px 15px 5px 25px;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
}

.job-select-box button span {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 100%;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.job-select-box button:focus {
  background-color: #151d4c;
  color: #ffffff;
}

.job-select-box button:focus span {
  background-color: #ffffff;
}

.new-job h5 {
  font-weight: bold;
  color: #151d4c;
  margin-bottom: 0;
}

.job-select-box {
  margin-top: 20px;
}

.next-job a {
  font-size: 16px;
  text-transform: uppercase;
  background-color: #151d4c;
  border-radius: 5px;
  padding: 5px 30px;
  color: #ffffff !important;
}

.new-job-select-page .input-four {
  margin: 0 0 10px 0;
}

/**********************************************/
/* 06. Job Selected Page CSS */
.new-job-selected-page .new-job {
  margin-bottom: 10px;
}

.new-job-selected-page .input-four {
  text-align: left;
}

.custom-input .add-extra i {
  font-size: 18px;
  margin-right: 10px;
}

.new-job-selected-page .custom-button a,
.new-job-selected-page .custom-button button {
  margin-top: 20px;
}

.new-job-selected-page.selected form.custom-form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.new-job-selected-page.selected form.custom-form .custom-input {
  width: 50%;
  flex: 0 0 50%;
  padding: 0 15px;
}

.new-job-selected-page.selected .custom-button {
  margin: 0 auto;
}

.new-job-selected-page .dropdown {
  width: 40%;
  border-radius: 5px;
  padding: 3px;
}


/**********************************************/
/* 07. Job List Page CSS */
.calender-box {
  max-width: 163px;
  margin: 0 auto;
  text-align: center;
  padding: 0;
  /* border: 1px solid #151d4c; */
  color: #151d4c;
  position: relative;
}

.calender-box .form-control {
  border: 1px solid #151d4c;
  border-radius: 0;
  text-align: center;
}

.date-previous {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 30px 0;
  border-color: transparent #161e4d transparent transparent;
  position: absolute;
  left: -50px;
  top: 0;
}

.date-next {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 30px 30px;
  border-color: transparent transparent transparent #161e4d;
  position: absolute;
  right: -50px;
  top: 0px;
}

.job-list-wrap {
  margin-top: 30px;
}

/**********************************************/
/* 08. Timekeeping Page CSS */
.timer-form input {
  width: 48%;
  display: inline-block;
  border-radius: 5px !important;
  text-align: center;
  border-radius: 0;
  border: 1px solid #161e4d;
  background: transparent;
  color: #000;
  opacity: 1;
}

.timer-form button {
  width: 48%;
  float: right;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 7px 0;
}

.timer-form input::-webkit-input-placeholder {
  color: #161e4d;
}

.timer-form input::placeholder {
  color: #161e4d;
}

.timer-form button.btn-start {
  background-color: #70ad47;
}

.timer-form button.btn-stop {
  background-color: #ff0000;
}

.notification p {
  background-color: #fff2cc;
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 5px;
  margin-top: 2px !important;
}

.timekeeping-details {
  margin-top: 20px;
}

.timekeeping-details .timer-form input:first-child {
  margin-right: 10px;
}

.timekeeping-details .timer-form {
  display: flex;
}

.timekeeping-page.manual .calender-box {
  margin: 0 auto 20px;
}

.timekeeping-details .timer-form input {
  width: 50%;
}

.timekeeping-page .new-job {
  margin-bottom: 10px;
}

.timekeeping-page.manual .timer-form {
  margin-top: 10px;
}

.timekeeping-page.manual .timekeeping-details {
  margin-top: 10px;
}

.timekeeping-details .timer-form input.bg-blue {
  background-color: #151d4c;
  color: #fff !important;
}

.timekeeping-details .timer-form input.bg-blue::-webkit-input-placeholder {
  color: #fff !important;
}

.timekeeping-details .timer-form input.bg-blue::placeholder {
  color: #fff !important;
}

/**********************************************/
/* 09. Work Report Page CSS */
.work-report-page .nav.nav-tabs {
  justify-content: center;
  border: none;
}

.work-report-page .timekeeping-details .timer-form {
  margin-top: 10px;
}

.work-report-page .nav.nav-tabs a {
  border-radius: 0;
  padding: 5px 10px;
  width: 120px;
  text-align: center;
  border: 1px solid #151d4c !important;
}

.work-report-page .nav.nav-tabs a.active {
  background-color: #28a745;
  color: #fff !important;
}

.work-report-page .calender-box {
  margin-top: 15px;
  margin-bottom: 15px;
}

.several-entrys tr td:first-child {
  width: 80%;
}

.several-entrys {
  margin-bottom: 5px;
}

.work-report-box {
  margin: 0 auto;
}

/**********************************************/
@media only screen and (max-width: 991px) {
  .bm-burger-button {
    right: 15px;
  }

  .back-menu {
    left: 15px;
  }

  .timekeeping-details {
    margin-top: 10px;
  }

  .timekeeping-details .timer-form {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {

  .bm-burger-button {
    width: 27px;
    top: 45px;
    height: 23px;
  }
}

@media only screen and (max-width: 575px) {
  .brand-logo img {
    max-width: 100px;
  }

  .work-report-box {
    max-width: 500px;
    margin: 0 auto;
    height: calc(100vh - 273px);
    overflow: scroll;
  }

  .job-list-wrap {
    height: calc(100vh - 238px);
    overflow: scroll;
  }

  .job-select-box {
    height: calc(100vh - 184px);
    overflow: scroll;
  }

  .new-job-select-page.activity .job-select-box {
    height: calc(100vh - 240px);
  }

  section.main-section.new-job-select-page {
    padding: 115px 0px 15px 0;
  }

  section.main-section.job-list-page {
    padding: 115px 0 55px 0;
  }

  .work-report-page.main-section {
    padding: 115px 0px 60px 0 !important;
  }

  .bm-burger-button {
    top: 35px;
  }

  .main-section {
    padding: 115px 0px 85px 0;
  }

  .custom-button {
    text-align: center;
    position: fixed;
    bottom: 0;
    right: 15px;
    width: auto;
    left: 15px;
    background-color: #fff;
    padding: 10px 0 15px;
  }

  .custom-input.label-left label {
    width: 30%;
  }

  .custom-input.label-left input {
    width: 70%;
    margin-left: 0;
  }

  .custom-button a,
  .custom-button button {
    margin-top: 0;
    display: block;
    width: 100%;
  }

  .back-menu img {
    max-width: 30px;
  }

  section.main-section.new-job-selected-page {
    padding: 115px 0px 135px 0;
  }

  .timer-box {
    margin-top: 10px;
  }

  section.main-section.new-job-selected-page.selected .custom-form {
    height: calc(100vh - 255px);
    overflow: scroll;
    padding-bottom: 20px;
  }

  section.main-section.new-job-selected-page.selected {
    padding: 115px 0px 106px 0;
  }

  .new-job-selected-page.selected form.custom-form .custom-input {
    width: 100%;
    flex: 0 0 100%;
  }

  .new-job-selected-page.selected form.custom-form {
    display: block;
  }

  .timekeeping-page.manual .timekeeping-details-wrap {
    height: calc(500px - -40px);
    overflow: scroll;
  }

  .timekeeping-page.manual {
    padding: 115px 0px 55px 0;
  }
}

.pointerCursor {
  cursor: pointer;
}

.center {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px;
}

div.loader {
  font-size: 100px;
}

.counter {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  right: 15px;
  top: 12px;
}

.card-title .counter {
  right: 0;
  top: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.card.baseCard {
  border: 1px solid #151d4c;
  color: #151d4c;
}

.editableClass {
  background: #fcfce0 !important;
}

.customDatePicker {
  height: 61px;
}

.scrollable {
  max-height: 500px;
  overflow: auto;
}

.timer-form .textOnlyClass {
  border: none;
  box-shadow: 2px 2px #f3f3f3;
  text-align: left;
}

.back_lightgreen {
  background: #90ee90;
}

.weekendBackground {
  background: #A9A9A9;
}
.highlighty{
  background: #fbfb8b ;
}
.background_mnual{
  background: #f76f6f;
}

.bgDarkGrey {
  background-color: #b0b0b0;
}

@media (max-width: 767px) {

  .table-bordered td,
  .table-bordered th {
    font-size: 12px;
    padding: 4px;
  }
}

.timeKeepingTitle {
  font-size: 24px;
  font-weight: bolder;
  padding: 7px 10px;
}

.marginBottom {
  margin-bottom: 20px;
}

.menuIcon {
  float: left;
  margin-right: 5px;
}

.menuText {
  float: left;
  width: 88%;
}

.bm-item.menu-item {
  overflow: hidden;
}

.btn-block .btn-success {
  width: 100%;
}

.bm-item {
  margin-bottom: 40px;
}

.fontSize15 {
  font-size: 15px !important;
}

.fontSize20 {
  font-size: 20px !important;
}

.card.baseCard label {
  color: #000;
}

.baseCard .form-control {
  color: #000;
}

.fileContainer .uploadPictureContainer .deleteImage {
  background: #161e4d;
}

.cardCustom {
  border: 1px solid #161e4d !important;
  margin-bottom: 5px;
}

.cardCustom .card-header {
  color: white;
  background: #3a4377;
}

.css-2613qy-menu {
  color: black;
  z-index: 99999999999999;
}

.borderNone {
  border: none !important;
}

.paddingTable.table td,
.paddingTable.table th {
  padding: 0.50rem;
}

/* photobook */

.bsPlus {
  font-size: 25px !important;
  line-height: 25px;
  border: 1px solid #28a745;
  border-radius: 3px;
  padding: 5px 9px;
  cursor: pointer;
  color: #28a745;
}

.bsPlus:hover {
  background-color: #28a745;
  color: #fff;
  transition: all 0.3s;
}

.dropdown_data {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 11rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0.25rem;
}

.dropdown_data li a {
  padding: 8px 18px !important;
}

.dropdown_data li:hover {
  background-color: #28a745;
}

.dropdown_data li:hover a {
  color: #fff !important;
}

.rotate_image {
  font-size: 30px;
  cursor: pointer;
}

.rotate_image:hover {
  color: #8a8a8a;
}
span, strong{
  display: inline;
}
.radioLabel {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  user-select: none;
}
.radioLabel input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.radioLabel input:checked ~ .checkmark {
  background-color: #161E4D;
}
.btn-punctus {
  background-color: #161E4D;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radioLabel input:checked ~ .checkmark:after {
  display: block;
}

.radioLabel .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.w-85px{
  width: 85px;
}
.pointer{
  cursor: pointer;
}
.btnDisable {
  pointer-events: none;
  opacity: 0.5;
}
